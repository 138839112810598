import React from "react"
import { Container, Row, Col, Section } from "react-bootstrap"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import LayoutHome from "../components/layout-home"
import SEO from "../components/seo"

const IndexPage = ({ data }) => (
  <LayoutHome pageInfo={{ pageName: "EduToolKit" }}>
    <SEO title="EduToolKit" keywords={[`inteligencia Artificial`, `EduToolKit`, `data`]} />
    <Container className="text-center">
      <section id="about" className="about-section">
        <Row>
          <Col><br /><br />
            <h2>Descripción del proyecto</h2>
            <p className="text-justify">
              EduToolKit es un proyecto que implementa la inteligencia artificial con base en un cuestionario que evalúa la competencia de emprendimiento social y ofrece una serie de herramientas educativas de aprendizaje adaptativo para acompañar el proceso formativo de estudiantes durante el desarrollo de proyectos de emprendimiento social. EduToolKit es una herramienta de apoyo para profesores que no son de emprendimiento social y desean fomentar esta competencia en sus estudiantes. Funciona también como una herramienta de aprendizaje adaptativo que permite a los estudiantes desarrollar un aprendizaje autónomo durante el proceso de diseño de propuestas de valor social
            </p>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Link to="edutoolkit"><h2 className="text-align-center">Kit de herramientas</h2></Link>
            <p className="text-align-center">
              Herramientas para el desarrollo de la competencia de emprendimiento social durante el proceso emprendedor.
            </p>
          </Col>
          <Col>
            <Link to="edutoolkit"><h2 className="text-align-center">Innovación Educativa </h2></Link>
            <p className="text-align-center">Invención y aplicación de novedades que aporten un valor agregado en procesos educativos.
            </p>
          </Col>
          <Col>
            <Link to="edutoolkit"><h2 className="text-align-center">Competencia de emprendimiento social</h2></Link>
            <p className="text-align-center">Desarrollar competencias de emprendimiento social y de innovación.
            </p>
          </Col>
        </Row>
        <br />
        <br />
      </section>
    </Container>
  </LayoutHome>
)

export const query = graphql`
  query {
      fileName: file(relativePath: {eq: "demo-image-01.jpg"  }) {
      childImageSharp {
      fluid(maxWidth: 300) {
      ...GatsbyImageSharpFluid
    }
    }
  }
}
`

export default IndexPage

