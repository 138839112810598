/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Image, Carousel, Container, Row, Col } from "react-bootstrap"
import Navbar from "./navBarHome"
import logoNovus from "../images/novus.png"
import { Link } from "gatsby"

import primera from "../images/22590.jpg"
import segunda from "../images/ecosistema_TEC-Edutoolkit.png"
import tercera from "../images/edutoolkit_Edutoolkit.png"
import cuarta from "../images/el_proyecto_Edutoolkit.png"
import quinta from "../images/investigacion1.png"

import Typewriter from 'typewriter-effect';
// Smooth scrolling using jQuery easing
var timer = setTimeout(() => {
  window.location.reload();
}, 3000);
clearTimeout(timer);

const LayoutHome = ({ children, pageInfo }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQueryHome {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Container fluid className="px-0 main" style={{ padding: 0 }}>
          <Navbar pageInfo={pageInfo} />
          <Row className="justify-content-center">
            <Col>
              {/* <header className="masthead mastheadbackhome" >
                <div className="container-fluid d-flex h-100 align-items-center">
                    <h1 className="cssanimation sequence fadeInBottom">EduToolKit</h1>
                      <Typewriter
                      
                      onInit={(typewriter) => {
                        typewriter.typeString('<h1>Kit de herramientas de inteligencia artificial</h1>')
                        typewriter.typeString('<h1 class="" style="color:#fff; font-size: 6vmin;">para desarrollar competencias de emprendimiento social e innovación</h1>')
                          .callFunction(() => {
                            console.log('String typed out!');
                          })
                          .changeDelay(1005)
                          .pauseFor(2500)
                          .callFunction(() => {
                            console.log('All strings were deleted');
                          })
                          .start();
                      }}
                    />
                </div>
              </header> */}
              <Carousel fade style={{ background: `black` }}>
                <Carousel.Item >
                  <Image fluid
                    className=""
                    src={primera}
                    alt="First slide"
                  />
                  <Carousel.Caption style={{ bottom: `15rem`, right: `0%`, left: `0%`, textAlign: `left` }}>
                    <div className="container-fluid align-items-left">

                      <Typewriter
                        options={{
                          autoStart: true,
                          delay: 75,
                          cursor: " ",
                        }}
                        onInit={(typewriter) => {
                          typewriter.typeString('<h1 style="font-size:10vmin; color:#fff">E</h1>')
                            .pauseFor(500)
                            .deleteAll()
                          typewriter.typeString('<h1 style="font-size:10vmin; color:#fff""><strong>EduToolKit</strong></h1>')

                            .start();
                        }} />
                      <p style={{ fontSize: `4vmin`, color: `#fff` }}>Kit de herramientas de inteligencia artificial</p>
                      <p style={{ fontSize: `2.5vmin`, color: `#fff` }} className="cssanimation sequence fadeInBottom">para desarrollar competencias de emprendimiento social e innovación</p>
                    </div>

                  </Carousel.Caption>

                </Carousel.Item>
                <Carousel.Item interval={2500}>
                  <Link to="/proyecto" className="link-no-style">
                    <Image fluid
                      className=""
                      src={cuarta}
                      alt="Third slide"
                    />
                  </Link>
                </Carousel.Item>
                <Carousel.Item interval={2500}>
                  <Link to="/ecosistema" className="link-no-style">
                    <Image fluid
                      className=" "
                      src={segunda}
                      alt="Second slide"
                    />
                  </Link>
                </Carousel.Item >
                <Carousel.Item interval={2500}>
                  <Link to="/edutoolkit" className="link-no-style">
                    <Image fluid
                      className=""
                      src={tercera}
                      alt="Third slide"
                    />
                  </Link>
                </Carousel.Item>
                <Carousel.Item interval={2500}>
                  <Link to="/investigacion" className="link-no-style">
                    <Image fluid
                      className=""
                      src={quinta}
                      alt="Third slide"
                    />
                  </Link>
                </Carousel.Item >
              </Carousel>

            </Col>
            <div class="containerArrow">
              <Link to="#about" >
                <div class="chevron"></div>
                <div class="chevron"></div>
                <div class="chevron"></div>
              </Link>
            </div>
          </Row>
          <Row noGutters>
            <Col>
              <Container className="mt-5 body-container">
                <main>{children}</main>
              </Container>
            </Col>
          </Row>
        </Container>
        <Container fluid className="px-0">
          <Row>
            <Col className="footer-col">
              <footer style={{ marginTop: `0px` }}>
                <span>
                <img src={logoNovus} alt="logoNovus" style={{ maxHeight: `75px` }} />
                  <br />
                   {new Date().getFullYear()}, <a href="https://novus.itesm.mx/" rel="noopener noreferrer" className="link-no-style" style={{ color: `#fff` }} target="_blank"> Novus </a> - EduToolKit <br />
                  <img src="https://static.wixstatic.com/media/446d2f_82746ead81a447a4b94ec6572070b933~mv2.png/v1/fill/w_114,h_38,al_c,lg_1,q_85/cc.webp" alt="cc.png" style={{ width: `91px`, height: `30px` }}></img>
                  <p>Este obra está bajo una <a href="http://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank" rel="noreferrer noopener">licencia de Creative Commons Reconocimiento-NoComercial-CompartirIgual 4.0 Internacional</a></p>

                </span>
              </footer>
            </Col>
          </Row>
        </Container>
      </>
    )}
  />
)

export default LayoutHome
